import "../../../App.css";
import "./GotQuestions.css";
import Content, { ContentNS } from "../../../content/content";
import clsx from "clsx";
import { extenalLinks } from "../../../content/external-links";
import Images from "../../../assets/images";

export default function GotQuestions() {
  const t = Content.shared.t;
  const textDir = Content.shared.instance.dir();

  return (
    <div className="got-question">
      <div className="got-question-content">
        <div className="got-question-title" dir={textDir}>
          {`${t("got-question", { ns: ContentNS.business })}`}
        </div>
        <div className="got-question-contacts">
          <div className="email-container">
            <img src={Images.b2bIconSend} />

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:hello@zamna.com"
            >
              hello@zamna.com
            </a>
          </div>
          <div className="social">
            <div className="social-item">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={extenalLinks.linkedin}
              >
                <img src={Images.logos.b2bLinkedIn} />
                <div className="social-title">linkedin</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
